import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal } from "antd";

import { MailOutlined } from "@ant-design/icons";

// @ts-ignore
import { inviteOwner } from "modules/actions/UserActions";
// @ts-ignore
import { useParams } from "react-router-dom";

// @ts-ignore
import { UserService } from "../../../../../api/userService";

interface IInviteUserModal {
  isActive: boolean;
  emailToInvite: string;
  onClose: () => void;
  setIsVerifiedEmail: (value: boolean) => void;
  allUsers: any;
  handleChange: any;
  selectedUsers: any;
  setHasChanges: any;
  isNewUser?: boolean;
  setIsNewUser: (value: boolean) => void;
}

const InviteUserModal: FC<IInviteUserModal> = ({
  isActive,
  emailToInvite,
  onClose,
  setIsVerifiedEmail,
  handleChange,
  selectedUsers,
  setHasChanges,
  isNewUser,
  setIsNewUser,
}) => {
  // @ts-ignore
  const { data } = useSelector((state) => state.user.invitedUser);

  const { id } = useParams();
  const dispatch = useDispatch();

  // @ts-ignore
  const { users } = useSelector((state) => state.user.projectOwners);

  const [userId, setUserId] = useState<any>("");

  const [isLoading, setIsLoading] = useState(false);

  const handleOwnerModal = () => {
    onClose();
    setIsLoading(false);
  };

  const handleInvite = async () => {
    setIsLoading(true);

    try {
      const payload = { email: emailToInvite };

      const response = await UserService.post(
        `/user/is_registered_user/`,
        payload
      );

      const isRegistered = response?.data?.is_registered;
      const user = response?.data?.user;

      if (isRegistered) {
        setIsNewUser(true);
        const payload = { invited_owner: emailToInvite };
        const qs = ``;
        dispatch(inviteOwner(payload, handleOwnerModal(), qs));
      }
    } catch (error) {
      // @ts-ignore
      const errorResponse = error?.response?.data?.is_registered;
      if (!errorResponse) {
        handleOwnerModal();
        setIsVerifiedEmail(true);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data?.user) {
      onClose();
      setIsLoading(false);
      handleChange("selectedUsers", [...selectedUsers, data?.user]);
      handleChange("peoplePop", true);
      setHasChanges(true);
    }
  }, [data?.user]);

  return (
    <Modal footer={null} onCancel={onClose} title={false} open={isActive}>
      <div className="font-18 mb-1">Share this action</div>

      <div className="ownerModalEmail">
        <div className="ownerEmailDiv">{emailToInvite}</div>
      </div>

      <Button
        icon={<MailOutlined />}
        className="inviteButton"
        type="primary"
        loading={isLoading}
        onClick={handleInvite}
      >
        Invite
      </Button>
    </Modal>
  );
};

export default InviteUserModal;
