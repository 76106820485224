const location = window.location.href;

const apiProdURL = 'apps.arootah.com';
// const apiStagingURL = 'project-planner-36424-staging.botics.co';
const apiDevelopURL = 'dev.pp.arootah.com';

const userProdURL = 'auth.arootah.com';
const userStagingURL = 'compensation-tool-3752-staging.botics.co';
const userDevURL = 'dev.auth.arootah.com';

let apiURL = apiProdURL;
let userURL = userProdURL;

// Array of staging urls
const validURLs = [
  'apps-staging.arootah.com',
  'project-planner-36424-staging.botics.co',
  'localhost'
];

const validDevelopURLs = [
  apiDevelopURL
]

// Checking if current url is a valid url
if (process.env.NODE_ENV === 'development' || validURLs.some(url => location.includes(url))) {
  apiURL = apiDevelopURL;
  userURL = userStagingURL;
}
else if (validDevelopURLs.some(url => location.includes(url))) {
  apiURL = apiDevelopURL;
  userURL = userDevURL;
}

console.log(`${apiURL === apiProdURL && process.env.NODE_ENV === 'production' ? '🟢' : '🟡'} API:`, apiURL);

export const apiBaseURL = `https://${apiURL}/`;
export const apiWebsocketURL = `wss://${apiURL}/ws/chat/`
export const apiUserServiceURL = `https://${userURL}/api/v1/`;

export const isProduction = apiURL === apiProdURL;

export const PROJECT = {
  DM: "decision-manager",
  AT: "allocator-tool",
  PP: "project-planner",
  MS: "mission-statement",
};

export const getProject = (pathname) => {
  if (pathname.includes(PROJECT.PP) || pathname.includes('shared-project')) return PROJECT.PP;
  else if (pathname.includes(PROJECT.DM) || pathname.includes('shared-decision')) return PROJECT.DM;
  else if (pathname.includes(PROJECT.AT) || pathname.includes('shared-decision')) return PROJECT.AT;
  else if (pathname.includes(PROJECT.MS)) return PROJECT.MS;
};

export const ScoreData = [
  { score: 0, color: "#0BA7F6" },
  { score: 1, color: "#1AADE8" },
  { score: 2, color: "#26B1DD" },
  { score: 3, color: "#35B7CF" },
  { score: 4, color: "#44BDC0" },
  { score: 5, color: "#53C2B2" },
  { score: 6, color: "#62C8A3" },
  { score: 7, color: "#71CD95" },
  { score: 8, color: "#80D386" },
  { score: 9, color: "#90D978" },
  { score: 10, color: "#9EDE6A" },
];
export const WeightDropDownList = [
  { label: "10", value: 10 },
  { label: "9", value: 9 },
  { label: "8", value: 8 },
  { label: "7", value: 7 },
  { label: "6", value: 6 },
  { label: "5", value: 5 },
  { label: "4", value: 4 },
  { label: "3", value: 3 },
  { label: "2", value: 2 },
  { label: "1", value: 1 },
];
export const NegativeWeightDropDownList = [
  { label: "-10", value: -10 },
  { label: "-9", value: -9 },
  { label: "-8", value: -8 },
  { label: "-7", value: -7 },
  { label: "-6", value: -6 },
  { label: "-5", value: -5 },
  { label: "-4", value: -4 },
  { label: "-3", value: -3 },
  { label: "-2", value: -2 },
  { label: "-1", value: -1 },
];
export const DurationHrsData = [];
export const DurationMinData = [];

for (let i = 1; i <= 8; i++) {
  DurationHrsData.push(i);
}

for (let i = 1; i <= 59; i++) {
  DurationMinData.push(i);
}

export const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

export const Days = [
  { key: "Monday", value: 1 },
  { key: "Tuesday", value: 2 },
  { key: "Wednesday", value: 3 },
  { key: "Thursday", value: 4 },
  { key: "Friday", value: 5 },
  { key: "Saturday", value: 6 },
  { key: "Sunday", value: 7 },
];

export const weekDays = ["mon", "tue", "wed", "thu", "fri"];

export const weekendDays = ["sat", "sun"];

export const months = {
  1: "jan",
  2: "feb",
  3: "mar",
  4: "apr",
  5: "may",
  6: "jun",
  7: "jul",
  8: "aug",
  9: "sep",
  10: "oct",
  11: "nov",
  12: "dec",
};

export const addbuttonStyles = {
  padding: "8px 12px",
  width: "max-content",
  background: "#35aa22",
};

export const cancelButtonStyles = {
  ...addbuttonStyles,
  background: "#fff",
  border: "1px solid #E3E5E9",
  color: "#1689CA",
  marginRight: "12px",
};

export const REPEAT = ["Weekly", "Monthly", "Daily", "Custom"];
export const DAY_NUMBER = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

export const durationList = [
  { key: "10 Min", value: "00:10:00" },
  { key: "20 Min", value: "00:20:00" },
  { key: "30 Min", value: "00:30:00" },
  { key: "40 Min", value: "00:40:00" },
  { key: "50 Min", value: "00:50:00" },
  { key: "1 Hr", value: "01:00:00" },
  { key: "1 Hr 10 Min", value: "01:10:00" },
  { key: "1 Hr 20 Min", value: "01:20:00" },
  { key: "1 Hr 30 Min", value: "01:30:00" },
  { key: "1 Hr 40 Min", value: "01:40:00" },
  { key: "1 Hr 50 Min", value: "01:50:00" },
  { key: "2 Hrs", value: "02:00:00" },
  { key: "2 Hr 10 Min", value: "02:10:00" },
  { key: "2 Hr 20 Min", value: "02:20:00" },
  { key: "2 Hr 30 Min", value: "02:30:00" },
  { key: "2 Hr 40 Min", value: "02:40:00" },
  { key: "2 Hr 50 Min", value: "02:50:00" },
  { key: "3 Hrs", value: "03:00:00" },
  { key: "3 Hr 10 Min", value: "03:10:00" },
  { key: "3 Hr 20 Min", value: "03:20:00" },
  { key: "3 Hr 30 Min", value: "03:30:00" },
  { key: "3 Hr 40 Min", value: "03:40:00" },
  { key: "3 Hr 50 Min", value: "03:50:00" },
  { key: "4 Hrs", value: "04:00:00" },
  { key: "4 Hr 10 Min", value: "04:10:00" },
  { key: "4 Hr 20 Min", value: "04:20:00" },
  { key: "4 Hr 30 Min", value: "04:30:00" },
  { key: "4 Hr 40 Min", value: "04:40:00" },
  { key: "4 Hr 50 Min", value: "04:50:00" },
  { key: "5 Hrs", value: "05:00:00" },
  { key: "5 Hr 10 Min", value: "05:10:00" },
  { key: "5 Hr 20 Min", value: "05:20:00" },
  { key: "5 Hr 30 Min", value: "05:30:00" },
  { key: "5 Hr 40 Min", value: "05:40:00" },
  { key: "5 Hr 50 Min", value: "05:50:00" },
  { key: "6 Hrs", value: "06:00:00" },
  { key: "6 Hr 10 Min", value: "06:10:00" },
  { key: "6 Hr 20 Min", value: "06:20:00" },
  { key: "6 Hr 30 Min", value: "06:30:00" },
  { key: "6 Hr 40 Min", value: "06:40:00" },
  { key: "6 Hr 50 Min", value: "06:50:00" },
  { key: "7 Hrs", value: "07:00:00" },
  { key: "7 Hr 10 Min", value: "07:10:00" },
  { key: "7 Hr 20 Min", value: "07:20:00" },
  { key: "7 Hr 30 Min", value: "07:30:00" },
  { key: "7 Hr 40 Min", value: "07:40:00" },
  { key: "7 Hr 50 Min", value: "07:50:00" },
  { key: "8 Hrs", value: "08:00:00" },
  { key: "8 Hr 10 Min", value: "08:10:00" },
  { key: "8 Hr 20 Min", value: "08:20:00" },
  { key: "8 Hr 30 Min", value: "08:30:00" },
  { key: "8 Hr 40 Min", value: "08:40:00" },
  { key: "8 Hr 50 Min", value: "08:50:00" },
  { key: "9 Hrs", value: "09:00:00" },
  { key: "9 Hr 10 Min", value: "09:10:00" },
  { key: "9 Hr 20 Min", value: "09:20:00" },
  { key: "9 Hr 30 Min", value: "09:30:00" },
  { key: "9 Hr 40 Min", value: "09:40:00" },
  { key: "9 Hr 50 Min", value: "09:50:00" },
  { key: "10 Hrs", value: "10:00:00" },
  { key: "10 Hr 10 Min", value: "10:10:00" },
  { key: "10 Hr 20 Min", value: "10:20:00" },
  { key: "10 Hr 30 Min", value: "10:30:00" },
  { key: "10 Hr 40 Min", value: "10:40:00" },
  { key: "10 Hr 50 Min", value: "10:50:00" },
  { key: "11 Hrs", value: "11:00:00" },
  { key: "11 Hr 10 Min", value: "11:10:00" },
  { key: "11 Hr 20 Min", value: "11:20:00" },
  { key: "11 Hr 30 Min", value: "11:30:00" },
  { key: "11 Hr 40 Min", value: "11:40:00" },
  { key: "11 Hr 50 Min", value: "11:50:00" },
  { key: "12 Hrs", value: "12:00:00" },
  { key: "12 Hr 10 Min", value: "12:10:00" },
  { key: "12 Hr 20 Min", value: "12:20:00" },
  { key: "12 Hr 30 Min", value: "12:30:00" },
  { key: "12 Hr 40 Min", value: "12:40:00" },
  { key: "12 Hr 50 Min", value: "12:50:00" },
  { key: "13 Hrs", value: "13:00:00" },
  { key: "13 Hr 10 Min", value: "13:10:00" },
  { key: "13 Hr 20 Min", value: "13:20:00" },
  { key: "13 Hr 30 Min", value: "13:30:00" },
  { key: "13 Hr 40 Min", value: "13:40:00" },
  { key: "13 Hr 50 Min", value: "13:50:00" },
  { key: "14 Hrs", value: "14:00:00" },
  { key: "14 Hr 10 Min", value: "14:10:00" },
  { key: "14 Hr 20 Min", value: "14:20:00" },
  { key: "14 Hr 30 Min", value: "14:30:00" },
  { key: "14 Hr 40 Min", value: "14:40:00" },
  { key: "14 Hr 50 Min", value: "14:50:00" },
  { key: "15 Hrs", value: "15:00:00" },
  { key: "15 Hr 10 Min", value: "15:10:00" },
  { key: "15 Hr 20 Min", value: "15:20:00" },
  { key: "15 Hr 30 Min", value: "15:30:00" },
  { key: "15 Hr 40 Min", value: "15:40:00" },
  { key: "15 Hr 50 Min", value: "15:50:00" },
  { key: "16 Hrs", value: "16:00:00" },
  { key: "16 Hr 10 Min", value: "16:10:00" },
  { key: "16 Hr 20 Min", value: "16:20:00" },
  { key: "16 Hr 30 Min", value: "16:30:00" },
  { key: "16 Hr 40 Min", value: "16:40:00" },
  { key: "16 Hr 50 Min", value: "16:50:00" },
  { key: "17 Hrs", value: "17:00:00" },
  { key: "17 Hr 10 Min", value: "17:10:00" },
  { key: "17 Hr 20 Min", value: "17:20:00" },
  { key: "17 Hr 30 Min", value: "17:30:00" },
  { key: "17 Hr 40 Min", value: "17:40:00" },
  { key: "17 Hr 50 Min", value: "17:50:00" },
  { key: "18 Hrs", value: "18:00:00" },
  { key: "18 Hr 10 Min", value: "18:10:00" },
  { key: "18 Hr 20 Min", value: "18:20:00" },
  { key: "18 Hr 30 Min", value: "18:30:00" },
  { key: "18 Hr 40 Min", value: "18:40:00" },
  { key: "18 Hr 50 Min", value: "18:50:00" },
  { key: "19 Hrs", value: "19:00:00" },
  { key: "19 Hr 10 Min", value: "19:10:00" },
  { key: "19 Hr 20 Min", value: "19:20:00" },
  { key: "19 Hr 30 Min", value: "19:30:00" },
  { key: "19 Hr 40 Min", value: "19:40:00" },
  { key: "19 Hr 50 Min", value: "19:50:00" },
  { key: "20 Hrs", value: "20:00:00" },
  { key: "20 Hr 10 Min", value: "20:10:00" },
  { key: "20 Hr 20 Min", value: "20:20:00" },
  { key: "20 Hr 30 Min", value: "20:30:00" },
  { key: "20 Hr 40 Min", value: "20:40:00" },
  { key: "20 Hr 50 Min", value: "20:50:00" },
  { key: "21 Hrs", value: "21:00:00" },
  { key: "21 Hr 10 Min", value: "21:10:00" },
  { key: "21 Hr 20 Min", value: "21:20:00" },
  { key: "21 Hr 30 Min", value: "21:30:00" },
  { key: "21 Hr 40 Min", value: "21:40:00" },
  { key: "21 Hr 50 Min", value: "21:50:00" },
  { key: "22 Hrs", value: "22:00:00" },
  { key: "22 Hr 10 Min", value: "22:10:00" },
  { key: "22 Hr 20 Min", value: "22:20:00" },
  { key: "22 Hr 30 Min", value: "22:30:00" },
  { key: "22 Hr 40 Min", value: "22:40:00" },
  { key: "22 Hr 50 Min", value: "22:50:00" },
  { key: "23 Hrs", value: "23:00:00" },
  { key: "23 Hr 10 Min", value: "23:10:00" },
  { key: "23 Hr 20 Min", value: "23:20:00" },
  { key: "23 Hr 30 Min", value: "23:30:00" },
  { key: "23 Hr 40 Min", value: "23:40:00" },
  { key: "23 Hr 50 Min", value: "23:50:00" },
  { key: "24 Hrs", value: "1 00:00:00" },
  { key: "24 Hr 10 Min", value: "1 00:10:00" },
  { key: "24 Hr 20 Min", value: "1 00:20:00" },
  { key: "24 Hr 30 Min", value: "1 00:30:00" },
  { key: "24 Hr 40 Min", value: "1 00:40:00" },
  { key: "24 Hr 50 Min", value: "1 00:50:00" },
  { key: "25 Hrs", value: "1 01:00:00" },
  { key: "25 Hr 10 Min", value: "1 01:10:00" },
  { key: "25 Hr 20 Min", value: "1 01:20:00" },
  { key: "25 Hr 30 Min", value: "1 01:30:00" },
  { key: "25 Hr 40 Min", value: "1 01:40:00" },
  { key: "25 Hr 50 Min", value: "1 01:50:00" },
  { key: "26 Hrs", value: "1 02:00:00" },
  { key: "26 Hr 10 Min", value: "1 02:10:00" },
  { key: "26 Hr 20 Min", value: "1 02:20:00" },
  { key: "26 Hr 30 Min", value: "1 02:30:00" },
  { key: "26 Hr 40 Min", value: "1 02:40:00" },
  { key: "26 Hr 50 Min", value: "1 02:50:00" },
  { key: "27 Hrs", value: "1 03:00:00" },
  { key: "27 Hr 10 Min", value: "1 03:10:00" },
  { key: "27 Hr 20 Min", value: "1 03:20:00" },
  { key: "27 Hr 30 Min", value: "1 03:30:00" },
  { key: "27 Hr 40 Min", value: "1 03:40:00" },
  { key: "27 Hr 50 Min", value: "1 03:50:00" },
  { key: "28 Hrs", value: "1 04:00:00" },
  { key: "28 Hr 10 Min", value: "1 04:10:00" },
  { key: "28 Hr 20 Min", value: "1 04:20:00" },
  { key: "28 Hr 30 Min", value: "1 04:30:00" },
  { key: "28 Hr 40 Min", value: "1 04:40:00" },
  { key: "28 Hr 50 Min", value: "1 04:50:00" },
  { key: "29 Hrs", value: "1 05:00:00" },
  { key: "29 Hr 10 Min", value: "1 05:10:00" },
  { key: "29 Hr 20 Min", value: "1 05:20:00" },
  { key: "29 Hr 30 Min", value: "1 05:30:00" },
  { key: "29 Hr 40 Min", value: "1 05:40:00" },
  { key: "29 Hr 50 Min", value: "1 05:50:00" },
  { key: "30 Hrs", value: "1 06:00:00" },
  { key: "30 Hr 10 Min", value: "1 06:10:00" },
  { key: "30 Hr 20 Min", value: "1 06:20:00" },
  { key: "30 Hr 30 Min", value: "1 06:30:00" },
  { key: "30 Hr 40 Min", value: "1 06:40:00" },
  { key: "30 Hr 50 Min", value: "1 06:50:00" },
  { key: "31 Hrs", value: "1 07:00:00" },
  { key: "31 Hr 10 Min", value: "1 07:10:00" },
  { key: "31 Hr 20 Min", value: "1 07:20:00" },
  { key: "31 Hr 30 Min", value: "1 07:30:00" },
  { key: "31 Hr 40 Min", value: "1 07:40:00" },
  { key: "31 Hr 50 Min", value: "1 07:50:00" },
  { key: "32 Hrs", value: "1 08:00:00" },
  { key: "32 Hr 10 Min", value: "1 08:10:00" },
  { key: "32 Hr 20 Min", value: "1 08:20:00" },
  { key: "32 Hr 30 Min", value: "1 08:30:00" },
  { key: "32 Hr 40 Min", value: "1 08:40:00" },
  { key: "32 Hr 50 Min", value: "1 08:50:00" },
  { key: "33 Hrs", value: "1 09:00:00" },
  { key: "33 Hr 10 Min", value: "1 09:10:00" },
  { key: "33 Hr 20 Min", value: "1 09:20:00" },
  { key: "33 Hr 30 Min", value: "1 09:30:00" },
  { key: "33 Hr 40 Min", value: "1 09:40:00" },
  { key: "33 Hr 50 Min", value: "1 09:50:00" },
  { key: "34 Hrs", value: "1 10:00:00" },
  { key: "34 Hr 10 Min", value: "1 10:10:00" },
  { key: "34 Hr 20 Min", value: "1 10:20:00" },
  { key: "34 Hr 30 Min", value: "1 10:30:00" },
  { key: "34 Hr 40 Min", value: "1 10:40:00" },
  { key: "34 Hr 50 Min", value: "1 10:50:00" },
  { key: "35 Hrs", value: "1 11:00:00" },
  { key: "35 Hr 10 Min", value: "1 11:10:00" },
  { key: "35 Hr 20 Min", value: "1 11:20:00" },
  { key: "35 Hr 30 Min", value: "1 11:30:00" },
  { key: "35 Hr 40 Min", value: "1 11:40:00" },
  { key: "35 Hr 50 Min", value: "1 11:50:00" },
  { key: "36 Hrs", value: "1 12:00:00" },
  { key: "36 Hr 10 Min", value: "1 12:10:00" },
  { key: "36 Hr 20 Min", value: "1 12:20:00" },
  { key: "36 Hr 30 Min", value: "1 12:30:00" },
  { key: "36 Hr 40 Min", value: "1 12:40:00" },
  { key: "36 Hr 50 Min", value: "1 12:50:00" },
  { key: "37 Hrs", value: "1 13:00:00" },
  { key: "37 Hr 10 Min", value: "1 13:10:00" },
  { key: "37 Hr 20 Min", value: "1 13:20:00" },
  { key: "37 Hr 30 Min", value: "1 13:30:00" },
  { key: "37 Hr 40 Min", value: "1 13:40:00" },
  { key: "37 Hr 50 Min", value: "1 13:50:00" },
  { key: "38 Hrs", value: "1 14:00:00" },
  { key: "38 Hr 10 Min", value: "1 14:10:00" },
  { key: "38 Hr 20 Min", value: "1 14:20:00" },
  { key: "38 Hr 30 Min", value: "1 14:30:00" },
  { key: "38 Hr 40 Min", value: "1 14:40:00" },
  { key: "38 Hr 50 Min", value: "1 14:50:00" },
  { key: "39 Hrs", value: "1 15:00:00" },
  { key: "39 Hr 10 Min", value: "1 15:10:00" },
  { key: "39 Hr 20 Min", value: "1 15:20:00" },
  { key: "39 Hr 30 Min", value: "1 15:30:00" },
  { key: "39 Hr 40 Min", value: "1 15:40:00" },
  { key: "39 Hr 50 Min", value: "1 15:50:00" },
  { key: "40 Hrs", value: "1 16:00:00" },
  { key: "40 Hr 10 Min", value: "1 16:10:00" },
  { key: "40 Hr 20 Min", value: "1 16:20:00" },
  { key: "40 Hr 30 Min", value: "1 16:30:00" },
  { key: "40 Hr 40 Min", value: "1 16:40:00" },
  { key: "40 Hr 50 Min", value: "1 16:50:00" },
  { key: "41 Hrs", value: "1 17:00:00" },
  { key: "41 Hr 10 Min", value: "1 17:10:00" },
  { key: "41 Hr 20 Min", value: "1 17:20:00" },
  { key: "41 Hr 30 Min", value: "1 17:30:00" },
  { key: "41 Hr 40 Min", value: "1 17:40:00" },
  { key: "41 Hr 50 Min", value: "1 17:50:00" },
  { key: "42 Hrs", value: "1 18:00:00" },
  { key: "42 Hr 10 Min", value: "1 18:10:00" },
  { key: "42 Hr 20 Min", value: "1 18:20:00" },
  { key: "42 Hr 30 Min", value: "1 18:30:00" },
  { key: "42 Hr 40 Min", value: "1 18:40:00" },
  { key: "42 Hr 50 Min", value: "1 18:50:00" },
  { key: "43 Hrs", value: "1 19:00:00" },
  { key: "43 Hr 10 Min", value: "1 19:10:00" },
  { key: "43 Hr 20 Min", value: "1 19:20:00" },
  { key: "43 Hr 30 Min", value: "1 19:30:00" },
  { key: "43 Hr 40 Min", value: "1 19:40:00" },
  { key: "43 Hr 50 Min", value: "1 19:50:00" },
  { key: "44 Hrs", value: "1 20:00:00" },
  { key: "44 Hr 10 Min", value: "1 20:10:00" },
  { key: "44 Hr 20 Min", value: "1 20:20:00" },
  { key: "44 Hr 30 Min", value: "1 20:30:00" },
  { key: "44 Hr 40 Min", value: "1 20:40:00" },
  { key: "44 Hr 50 Min", value: "1 20:50:00" },
  { key: "45 Hrs", value: "1 21:00:00" },
  { key: "45 Hr 10 Min", value: "1 21:10:00" },
  { key: "45 Hr 20 Min", value: "1 21:20:00" },
  { key: "45 Hr 30 Min", value: "1 21:30:00" },
  { key: "45 Hr 40 Min", value: "1 21:40:00" },
  { key: "45 Hr 50 Min", value: "1 21:50:00" },
  { key: "46 Hrs", value: "1 22:00:00" },
  { key: "46 Hr 10 Min", value: "1 22:10:00" },
  { key: "46 Hr 20 Min", value: "1 22:20:00" },
  { key: "46 Hr 30 Min", value: "1 22:30:00" },
  { key: "46 Hr 40 Min", value: "1 22:40:00" },
  { key: "46 Hr 50 Min", value: "1 22:50:00" },
  { key: "47 Hrs", value: "1 23:00:00" },
  { key: "47 Hr 10 Min", value: "1 23:10:00" },
  { key: "47 Hr 20 Min", value: "1 23:20:00" },
  { key: "47 Hr 30 Min", value: "1 23:30:00" },
  { key: "47 Hr 40 Min", value: "1 23:40:00" },
  { key: "47 Hr 50 Min", value: "1 23:50:00" },
  { key: "48 Hrs", value: "2 00:00:00" },
  { key: "48 Hr 10 Min", value: "2 00:10:00" },
  { key: "48 Hr 20 Min", value: "2 00:20:00" },
  { key: "48 Hr 30 Min", value: "2 00:30:00" },
  { key: "48 Hr 40 Min", value: "2 00:40:00" },
  { key: "48 Hr 50 Min", value: "2 00:50:00" },
  { key: "49 Hrs", value: "2 01:00:00" },
  { key: "49 Hr 10 Min", value: "2 01:10:00" },
  { key: "49 Hr 20 Min", value: "2 01:20:00" },
  { key: "49 Hr 30 Min", value: "2 01:30:00" },
  { key: "49 Hr 40 Min", value: "2 01:40:00" },
  { key: "49 Hr 50 Min", value: "2 01:50:00" },
  { key: "50 Hrs", value: "2 02:00:00" },
  { key: "50 Hr 10 Min", value: "2 02:10:00" },
  { key: "50 Hr 20 Min", value: "2 02:20:00" },
  { key: "50 Hr 30 Min", value: "2 02:30:00" },
  { key: "50 Hr 40 Min", value: "2 02:40:00" },
  { key: "50 Hr 50 Min", value: "2 02:50:00" },
  { key: "51 Hrs", value: "2 03:00:00" },
  { key: "51 Hr 10 Min", value: "2 03:10:00" },
  { key: "51 Hr 20 Min", value: "2 03:20:00" },
  { key: "51 Hr 30 Min", value: "2 03:30:00" },
  { key: "51 Hr 40 Min", value: "2 03:40:00" },
  { key: "51 Hr 50 Min", value: "2 03:50:00" },
  { key: "52 Hrs", value: "2 04:00:00" },
  { key: "52 Hr 10 Min", value: "2 04:10:00" },
  { key: "52 Hr 20 Min", value: "2 04:20:00" },
  { key: "52 Hr 30 Min", value: "2 04:30:00" },
  { key: "52 Hr 40 Min", value: "2 04:40:00" },
  { key: "52 Hr 50 Min", value: "2 04:50:00" },
  { key: "53 Hrs", value: "2 05:00:00" },
  { key: "53 Hr 10 Min", value: "2 05:10:00" },
  { key: "53 Hr 20 Min", value: "2 05:20:00" },
  { key: "53 Hr 30 Min", value: "2 05:30:00" },
  { key: "53 Hr 40 Min", value: "2 05:40:00" },
  { key: "53 Hr 50 Min", value: "2 05:50:00" },
  { key: "54 Hrs", value: "2 06:00:00" },
  { key: "54 Hr 10 Min", value: "2 06:10:00" },
  { key: "54 Hr 20 Min", value: "2 06:20:00" },
  { key: "54 Hr 30 Min", value: "2 06:30:00" },
  { key: "54 Hr 40 Min", value: "2 06:40:00" },
  { key: "54 Hr 50 Min", value: "2 06:50:00" },
  { key: "55 Hrs", value: "2 07:00:00" },
  { key: "55 Hr 10 Min", value: "2 07:10:00" },
  { key: "55 Hr 20 Min", value: "2 07:20:00" },
  { key: "55 Hr 30 Min", value: "2 07:30:00" },
  { key: "55 Hr 40 Min", value: "2 07:40:00" },
  { key: "55 Hr 50 Min", value: "2 07:50:00" },
  { key: "56 Hrs", value: "2 08:00:00" },
];