import {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { arrayMoveImmutable } from "array-move";
import { DropResult } from "react-beautiful-dnd";
import moment, { Moment } from "moment";
import { DatePicker, Popover } from "antd";
import { Button } from "elements";

import {
  Wrapper,
  Head,
  Col,
  WeightContent,
  NameContent,
  NameWrapper,
  AddActionItemBtn,
  Toggle,
  CollapseWrapper,
  Body,
  ActionButton,
  ProgressContainer,
  Status,
} from "./styled";

import { DraggableItem, NewItem } from "../Item";
import InputWrapper from "../InputWrapper";
import { DeleteItemModal } from "../DeleteItemModal";

import EditIcon from "../../../../assets/svg/edit.svg";
import TrashIcon from "../../../../assets/svg/trash.svg";

import upIcon from "./up.svg";
import disableArrow from "./disableArrow.svg";
import addIcon from "./add.svg";

import { ReactComponent as CompleteIcon } from "./complete.svg";
import { ReactComponent as TodoIcon } from "./todo.svg";
import { ReactComponent as InProgessIcon } from "./inProgress.svg";
import { ReactComponent as CalanderIcon } from "./calander.svg";
import { ReactComponent as LockIcon } from "../../../../assets/svg/lock.svg";

// @ts-ignore
import {
  changeActionItemsOrder,
  createActionItem,
  updatePlanCategory,
  // @ts-ignore
} from "modules/actions/PlanActions";

// @ts-ignore
import RoundedProgress from "../../PlanHeader/components/RoundProgress";

import { ICategory, IItem } from "../@types";

// @ts-ignore
import classes from "./styled.module.scss";

// @ts-ignore
import { getTime /* , getTimeText, validateEmail */ } from "utils/utility";
import {
  ValidateCategoryStartLineValue,
  ValidateCatrgoryDeadLineValue,
} from "../../../../utils/validateValue";
import ErrorTooltip from "../../../../components/ErrorTooltip";

// import ValidateValue from "../../../../utils";

interface IProps {
  data?: ICategory;
  additionalData?: {
    plan_start_date: string | null;
    plan_end_date: string | null;
  };
  isActive?: boolean;
  isNew?: boolean;
  isShort: boolean;
  isShared: boolean;
  onToggle?: (id: string) => void;
  onCreate?: (value: string) => void;
  onAdjustWeight?: () => void;
  isExpandAllNotes?: boolean;
  provided?: any;
  isAddCategory?: boolean;
  setIsAddCategory?: (value: boolean) => void;
}

interface IAddProps {
  isShared: boolean;
  isShort: boolean;
  onCreate: (value: string) => void;
  setIsAddCategory: (value: boolean) => void;
}

type ActionItem = {
  plan_progress: number;
};

interface IHandle {
  focusInput: () => void;
}

export const AddCategory: FC<IAddProps> = ({
  isShared,
  isShort,
  onCreate,
  setIsAddCategory,
}) => {
  const $categoryRef = useRef<IHandle>(null);

  useEffect(() => {
    $categoryRef.current?.focusInput();
  }, []);

  return (
    <Category
      isNew
      isShared={isShared}
      isShort={isShort}
      onCreate={onCreate}
      ref={$categoryRef}
      setIsAddCategory={setIsAddCategory}
    />
  );
};

const Category = forwardRef<IHandle, IProps>(
  (
    {
      data,
      additionalData,
      isActive,
      isNew,
      isShared,
      isShort,
      onToggle,
      onCreate,
      onAdjustWeight,
      isExpandAllNotes,
      provided,
      setIsAddCategory,
      isAddCategory,
    },
    ref
  ) => {
    const {
      id,
      name,
      duration,
      weight,
      plan_progress,
      category_progress,
      project_plan,
      start_line,
      dead_line,
      start,
      current,
      target,
      action_items,
    } = data || ({} as ICategory);

    const dispatch = useDispatch();

    const [isEditItemText, setIsEditItemText] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isAdd, setIsAdd] = useState(false);

    const [measurementPop, setMeasurementPop] = useState(false);
    const [categoryName, setCategoryName] = useState("");

    const $nameRef = useRef<IHandle>(null);
    const $newItemRef = useRef<IHandle>(null);

    const [values, setValues] = useState({
      start: start || null,
      current: current || null,
      target: target || null,
    });

    useImperativeHandle(ref, () => ({
      focusInput: () => {
        if ($nameRef.current) {
          $nameRef.current.focusInput();
        }
      },
    }));

    const onClickHandle = () => {
      if (onToggle) onToggle(id.toString());
    };

    const onDragEndHandle = (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const oldIndex = result.source.index;
      const newIndex = result.destination.index;

      if (oldIndex !== newIndex) {
        const newData = arrayMoveImmutable(
          action_items.slice(),
          oldIndex,
          newIndex
        );

        const actions: Pick<IItem, "id" | "order_id">[] = [];

        newData?.forEach((element, index) => {
          if (element) {
            actions?.push({
              id: element?.id,
              order_id: index,
            });
          }
        });

        const payload = {
          category: id,
          actions,
        };

        dispatch(changeActionItemsOrder(payload, project_plan));
      }
    };

    /* const getCategoryByID = (id: number) => {
    return planDetails?.categories?.find((e) => e?.id === c_id);
  }; */

    const handleCreateActionItem = (value: string) => {
      const payload = {
        name: value,
        start_line: moment(start_line ?? new Date()).format("YYYY-MM-DD"),
        dead_line: moment(dead_line ?? new Date()).format("YYYY-MM-DD"),
        project_plan: project_plan,
        category: id,
      };

      dispatch(createActionItem(payload));
      setIsAdd(false);
      // toggleExpandedKeys(record?.key);
    };

    const handleNameChange = (value: string, name: string) => {
      if (isNew) {
        onCreate?.("");
        return;
      }

      if (!value) return;

      dispatch(updatePlanCategory(id, { project_plan, [name]: value }, true));
    };

    const handleSaveCategoryName = () => {
      if (!categoryName) return;

      if (isNew) {
        onCreate?.(categoryName);
        return;
      }
      setCategoryName("");
    };

    const handleAddActionItem = () => {
      setIsAdd(true);
      setIsAddCategory && setIsAddCategory(false);
      if ($newItemRef && $newItemRef.current) {
        if (onToggle && !isActive) {
          onToggle(id.toString());

          setTimeout(() => {
            $newItemRef.current?.focusInput();
          }, 500);
        } else {
          $newItemRef.current?.focusInput();
        }
      }
    };

    const isDateDisabled = (
      current: Moment,
      name: "start_line" | "dead_line"
    ): boolean => {
      if (!current) return false;

      if (name === "start_line") {
        if (
          additionalData?.plan_start_date &&
          current.isBefore(moment(additionalData.plan_start_date), "day")
        ) {
          return true;
        }
        if (
          additionalData?.plan_end_date &&
          current.isAfter(moment(additionalData.plan_end_date), "day")
        ) {
          return true;
        }
      } else if (name === "dead_line") {
        if (
          additionalData?.plan_start_date &&
          current.isBefore(moment(additionalData.plan_start_date), "day")
        ) {
          return true;
        }
        if (
          additionalData?.plan_end_date &&
          current.isAfter(moment(additionalData.plan_end_date), "day")
        ) {
          return true;
        }
        if (start_line && current.isBefore(moment(start_line), "day")) {
          return true;
        }
      }

      return false;
    };

    const startPlanDate = additionalData?.plan_start_date || null;
    const endPlanDate = additionalData?.plan_end_date || null;

    const startLineError = ValidateCategoryStartLineValue(
      start_line,
      startPlanDate,
      endPlanDate
    );
    const deadLineError = ValidateCatrgoryDeadLineValue(
      dead_line,
      startPlanDate,
      endPlanDate,
      start_line
    );

    const getStatus = (items: ActionItem[], isCount: boolean): any => {
      if (items?.every((item) => item.plan_progress === 0)) {
        return isCount ? 0 : "To Do";
      }
      const completedCount = items?.filter(
        (item) => item.plan_progress === 10
      ).length;

      if (isCount) {
        return completedCount;
      }

      if (completedCount === items.length) {
        return "Completed";
      }

      if (
        items?.some((item) => item.plan_progress > 0 && item.plan_progress < 10)
      ) {
        return "In Progress";
      }

      return "Unknown";
    };

    const status = getStatus(action_items || [], false);
    const completedCount = getStatus(action_items || [], true);

    useEffect(() => {
      if (!data) return;
      setValues({
        start: start || null,
        current: current || null,
        target: target || null,
      });
    }, [data]);

    const handleChange = (e: any) => {
      const { name, value } = e.target;

      if (value === "" || /^[1-9][0-9]*$/.test(value) || value === "0") {
        setValues((prev) => ({
          ...prev,
          [name]: value === "" ? null : value,
        }));
      }
    };

    const handleUpdateMeasurement = () => {
      dispatch(
        updatePlanCategory(
          id,
          {
            start: values.start,
            current: values.current,
            target: values.target,
          },
          true
        )
      );

      setMeasurementPop(false);
    };

    const disableSaveButton =
      (values?.start === start || `${values?.start}.00` === start) &&
      (values?.current === current || `${values?.current}.00` === current) &&
      (values?.target === target || `${values?.target}.00` === target);

    useEffect(() => {
      setValues({
        start: start || null,
        current: current || null,
        target: target || null,
      });
    }, [measurementPop, !measurementPop]);

    useEffect(()=>{
      if(isAddCategory){
        setIsAdd(false);
      }
    },[isAddCategory])

    return (
      <Wrapper
        $color="#1C8B7E"
        isShort={isShort}
        ref={provided?.innerRef}
        {...provided?.droppableProps}
      >
        <Head>
          <Col>
            <input type="checkbox" />
          </Col>

          <Col>
            <Toggle
              $active={isActive}
              $isNew={isNew}
              onClick={onClickHandle}
              title="Collapse category"
              data-testid="expand-collapse-button"
            >
              {isNew ? (
                <img src={disableArrow} alt="" />
              ) : (
                <img src={upIcon} alt="" />
              )}
            </Toggle>
          </Col>
          <Col>
            <NameContent $disabled={isShared}>
              <NameWrapper>
                <InputWrapper
                  name="name"
                  defaultValue={name || ""}
                  placeholder="Category name"
                  disabled={isShared}
                  onChange={handleNameChange}
                  setInputValue={setCategoryName}
                  ref={$nameRef}
                  data-testid="category-input"
                  textCenter={false}
                  maxWordLength={255}
                  setIsEditItemText={setIsEditItemText}
                  isEditItemText={isEditItemText}
                  notEditOnText={true}
                  isBlur={isNew ? false : true}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSaveCategoryName();
                    }

                    if (e.key === "Escape") {
                      setIsEditItemText(false);
                      setIsAddCategory && setIsAddCategory(false);
                    }
                  }}
                />

                {isNew && (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      size="md"
                      styleType="secondary"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setIsAddCategory && setIsAddCategory(false);
                      }}
                      onMouseDown={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsAddCategory && setIsAddCategory(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="md"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onCLick={(e: any) => {
                        e.stopPropagation();
                        handleSaveCategoryName();
                      }}
                      onMouseDown={(e: any) => {
                        e.stopPropagation();
                        handleSaveCategoryName();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </NameWrapper>
              {isNew ? null : (
                <ProgressContainer>
                  <RoundedProgress
                    size={16}
                    progress={(completedCount / action_items?.length) * 100}
                    strokeWidth={2}
                    innerStrokeWidth={2}
                    circleTwoStroke="#1271A6"
                    circleOneStroke="#D0E3ED"
                    fill="transparent"
                  />
                  <span>
                    {completedCount}/{action_items?.length}
                  </span>
                </ProgressContainer>
              )}

              {isNew ? null : (
                <ActionButton className="items-actions">
                  <AddActionItemBtn
                    onClick={handleAddActionItem}
                    disabled={isShared}
                    data-testid="add-item-button"
                    style={{
                      opacity: isAdd ? 0.5 : 1,
                      pointerEvents: isAdd ? "none" : "auto",
                      cursor: isAdd ? "not-allowed" : "pointer",
                    }}
                  >
                    <img src={addIcon} alt="" />
                    Add Action Item
                  </AddActionItemBtn>

                  {isShared ? null : (
                    <>
                      <img
                        src={EditIcon}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsEditItemText(true)}
                      />

                      <img
                        src={TrashIcon}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsDeleteModalVisible(true)}
                      />
                    </>
                  )}

                  <DeleteItemModal
                    itemId={id}
                    itemName={name ?? ""}
                    planId={project_plan}
                    isVisible={isDeleteModalVisible}
                    type="category"
                    onClose={() => {
                      setIsDeleteModalVisible(false);
                    }}
                  />
                </ActionButton>
              )}
            </NameContent>
          </Col>

          <Col>
            {isNew ? null : (
              <WeightContent
                onClick={onAdjustWeight}
                disabled={isShared}
                title="Adjust weight..."
                data-testid="weight-category-button"
              >
                {(Number(weight) * 100).toFixed(2)}%
              </WeightContent>
            )}
          </Col>

          <Col data-testid="plan-progress-value">
            {isNew ? null : `${(Number(plan_progress) * 100).toFixed(2)}%`}
          </Col>

          {isNew ? null : (
            <Col>
              <Status>
                {status === "Completed" ? (
                  <>
                    <CompleteIcon /> Completed
                  </>
                ) : status === "In Progress" ? (
                  <>
                    <InProgessIcon />
                    In Progress
                  </>
                ) : (
                  <>
                    <TodoIcon />
                    To Do
                  </>
                )}

                {/* : status === "On Hold" ? (
                  <>
                    <OnHoldIcon />
                    On Hold
                  </>
                )  */}
              </Status>
            </Col>
          )}

          {isNew ? null : (
            <Col>
              <Popover
                content={
                  <div className="measurement-popover">
                    <div className="measurement-popover-items">
                      <div className="measurement-popover-item">
                        <label>Start</label>
                        <input
                          name="start"
                          type="number"
                          value={values?.start || ""}
                          placeholder="0"
                          disabled={isShared}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="measurement-popover-item">
                        <label>Current</label>
                        <input
                          name="current"
                          type="number"
                          value={values?.current || ""}
                          placeholder="0"
                          disabled={isShared}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="measurement-popover-item">
                        <label>Target</label>

                        <input
                          name="target"
                          type="number"
                          value={values?.target || ""}
                          placeholder="0"
                          disabled={isShared}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="measurement-popover-actions">
                      <button
                        onClick={() => {
                          setMeasurementPop(false);
                          setValues({
                            start: start || null,
                            current: current || null,
                            target: target || null,
                          });
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleUpdateMeasurement}
                        disabled={isShared || disableSaveButton}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                }
                trigger="click"
                placement="bottomRight"
                overlayClassName="measurement-popover-overlay"
                open={measurementPop}
                onOpenChange={(value) => {
                  setMeasurementPop(value);
                }}
                builtinPlacements={{
                  bottomRight: {
                    points: ["tc", "bc"],
                    offset: [-50, 20],
                  },
                }}
                // @ts-ignore
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                <div
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {current || "--"}
                </div>
              </Popover>
            </Col>
          )}

          <Col>
            <div className="d-flex align-items-center justify-content-center gap-1">
              {isNew ? null : isShared ? (
                start_line ? (
                  moment(start_line).format("YYYY-MM-DD")
                ) : null
              ) : (
                <DatePicker
                  // className={classes.datepicker}
                  className={`${classes.datepicker} antd-suffixIcon`}
                  // suffixIcon={<LockIcon />}
                  suffixIcon={<CalanderIcon />}
                  format="MMM D"
                  disabledDate={(current) =>
                    isDateDisabled(current, "start_line")
                  }
                  value={start_line ? moment(start_line) : null}
                  disabled={isShared}
                  onChange={(date, value) => {
                    if (date) {
                      const formattedDate = date.format("YYYY-MM-DD");
                      dispatch(
                        updatePlanCategory(
                          id,
                          {
                            start_line: value ? formattedDate : null,
                          },
                          true
                        )
                      );
                    } else {
                      dispatch(
                        updatePlanCategory(
                          id,
                          {
                            start_line: null,
                          },
                          true
                        )
                      );
                    }
                  }}
                  data-testid="start-category-date"
                />
              )}

              {startLineError && <ErrorTooltip title={startLineError || ""} />}
            </div>
          </Col>

          <Col>
            <div className="d-flex align-items-center justify-content-center gap-1">
              {isNew ? null : isShared ? (
                dead_line ? (
                  moment(dead_line).format("YYYY-MM-DD")
                ) : null
              ) : (
                <DatePicker
                  className={`${classes.datepicker} antd-suffixIcon`}
                  format="MMM D"
                  suffixIcon={<CalanderIcon />}
                  disabledDate={(current) =>
                    isDateDisabled(current, "dead_line")
                  }
                  value={dead_line ? moment(dead_line) : null}
                  disabled={isShared}
                  onChange={(date, value) => {
                    if (date) {
                      const formattedDate = date.format("YYYY-MM-DD");
                      dispatch(
                        updatePlanCategory(
                          id,
                          {
                            dead_line: date ? formattedDate : null,
                          },
                          true
                        )
                      );
                    } else {
                      dispatch(
                        updatePlanCategory(
                          id,
                          {
                            dead_line: null,
                          },
                          true
                        )
                      );
                    }
                  }}
                  data-testid="end-category-date"
                />
              )}
              {deadLineError && <ErrorTooltip title={deadLineError || ""} />}
            </div>
          </Col>

          {isNew ? null : (
            <Col $center data-testid="duration-category-value">
              {duration ? getTime(duration) : ""}
            </Col>
          )}

          {isNew ? null : <Col $center>--</Col>}

          {isNew ? null : (
            <Col>{/* <MenuIcon className="cursor-pointer" /> */}</Col>
          )}
        </Head>

        {isNew ? null : (
          <Accordion.Collapse as={CollapseWrapper} eventKey={id.toString()}>
            <>
              <Body>
                {action_items.map((item, i) => (
                  <DraggableItem
                    data={item}
                    additionalData={{
                      plan_start_date: additionalData?.plan_start_date || null,
                      plan_end_date: additionalData?.plan_end_date || null,
                      category_start_date: start_line,
                      category_end_date: dead_line,
                    }}
                    isExpand={isExpandAllNotes}
                    projectPlanId={project_plan}
                    isShared={isShared}
                    isShort={isShort}
                    index={i}
                    key={item.id}
                  />
                ))}
                {provided.placeholder}
              </Body>

              {isShared
                ? null
                : isAdd && (
                    <NewItem
                      isAdd={isAdd}
                      setIsAdd={setIsAdd}
                      projectPlanId={project_plan}
                      isShort={isShort}
                      ref={$newItemRef}
                      onCreate={handleCreateActionItem}
                    />
                  )}
            </>
          </Accordion.Collapse>
        )}
      </Wrapper>
    );
  }
);

export default Category;
